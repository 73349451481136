<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <table>
      <tbody>
        <tr>
          <td>
            <table class="document-selection-table" cellspacing="0" rowspacing="0">
              <thead>
                <tr>
                  <th>
                    {{ lang('ChecklistBuilder.templates.documents.document') }}
                  </th>
                  <th
                    style="width: 30px;"
                  />
                  <th>
                    {{ lang('ChecklistBuilder.templates.documents.file_name') }}
                  </th>
                  <th
                    style="width: 30px;"
                  />
                  <th
                    style="min-width: 40px; max-width: 115px;"
                  >
                    {{ lang('ChecklistBuilder.templates.documents.send') }}
                  </th>
                  <th
                    style="min-width: 40px; max-width: 115px;"
                  >
                    {{ lang('ChecklistBuilder.templates.documents.received') }}
                  </th>
                  <th>
                    {{ lang('ChecklistBuilder.templates.documents.comment') }}
                  </th>
                  <th
                    style="min-width: 40px; max-width: 90px;"
                  >
                    {{ lang('ChecklistBuilder.templates.documents.status') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(row, i) in value"
                >
                  <tr
                    v-if="row.relevance !== 2"
                    :key="`document_row_${i}`"
                    :data-entry="i"
                  >
                    <td>
                      {{ rowLabel(row) }}
                    </td>
                    <td>
                      <v-btn
                        small
                        type="button"
                        :class="`document-relevance${classes}`"
                        :disabled="disabled"
                        data-content-button-action="doc_relevance"
                        :title="lang('ChecklistBuilder.templates.documents.relevant')"
                      >
                        <v-icon
                          v-if="row.relevance === 3"
                          :class="`relevance-type-${row.relevance}`"
                        >
                          mdi-help
                        </v-icon>
                        <v-icon
                          v-else-if="row.relevance === 2"
                          :class="`relevance-type-${row.relevance}`"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon
                          v-else-if="row.check === 1"
                          :class="`relevance-type-${row.relevance}`"
                        >
                          mdi-check
                        </v-icon>
                        <v-icon
                          v-else
                          :class="`relevance-type-0`"
                        >
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <div>
                        <select
                          :class="`document-select${classes}`"
                          :disabled="disabled"
                          :style="isValidRelevance(row)"
                        >
                          <option
                            v-for="(document, j) in (row.documents || [])"
                            :key="`document_${j}`"
                            :value="document.id"
                            :data-type="document.action_type"
                            :selected="!!document.selected"
                          >
                            {{ document.file_name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <template
                        v-for="(document, k) in (row.documents || [])"
                      >
                        <v-btn
                          v-if="document.selected && ~~document.action_type === 1"
                          :key="`document_type_${k}`"
                          :class="`document-action${classes}`"
                          :disabled="disabled"
                          data-content-button-action="doc_generate"
                          small
                        >
                          <v-icon>
                            mdi-file
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-else-if="document.selected && ~~document.action_type === 0"
                          :key="`document_type_${k}`"
                          :class="`document-action${classes}`"
                          :disabled="disabled"
                          data-content-button-action="doc_download"
                          small
                        >
                          <v-icon>
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                    </td>
                    <td>
                      <div
                        :style="isValidRelevance(row)"
                      >
                        <template
                          v-if="showRowSend(row)"
                        >
                          <input
                            :class="`document-send${classes}`"
                            :disabled="disabled"
                            type="checkbox"
                            :value="~~!!row.send"
                            :checked="!!row.send"
                          >
                          <div
                            v-if="typeof row.send === 'number'"
                            class="document-send-date"
                          >
                            {{ sendDateTime(row) }}
                          </div>
                          <div
                            v-else
                            class="document-send-date"
                          >
                            {{ lang('ChecklistBuilder.misc.datetime') }}
                          </div>
                        </template>
                      </div>
                    </td>
                    <td>
                      <div
                        :style="isValidRelevanceReceived(row)"
                      >
                        <template
                          v-if="showRowReceived(row)"
                        >
                          <input
                            :class="`document-received${classes}`"
                            :disabled="disabled"
                            type="checkbox"
                            :value="~~!!row.received"
                            :checked="!!row.received"
                          >
                          <div
                            v-if="typeof row.received === 'number'"
                            class="document-received-date"
                          >
                            {{ receivedDateTime(row) }}
                          </div>
                          <div
                            v-else
                            class="document-received-date"
                          >
                            {{ lang('ChecklistBuilder.misc.datetime') }}
                          </div>
                        </template>
                      </div>
                    </td>
                    <td>
                      <template
                        v-if="row.settings.show_received"
                      >
                        <div
                          :style="isValidRelevanceComment(row)"
                        >
                          <input
                            :class="`document-comment${classes}`"
                            :disabled="disabled"
                            type="text"
                            :value="row.comment || ''"
                            :placeholder="lang('ChecklistBuilder.misc.comment')"
                          >
                        </div>
                      </template>
                    </td>
                    <td>
                      {{ rowStatus(row) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </td>
        </tr>
        <template v-if="button">
          <tr>
            <td>
              <v-btn
                :class="`no-clip ${classes}`"
                :disabled="disabledButton"
                type="button"
                data-content-button-action="show_hidden_documents"
                :title="lang('ChecklistBuilder.lang.titles.show_hidden_documents')"
                small
              >
                <v-icon>
                  mdi-file
                </v-icon>
                {{ lang('ChecklistBuilder.misc.buttons.documents_add') }}
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeDocumentManagement',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    button() {
      return !this.cell.settings.documents_hide_add
    },
    disabledButton() {
      for (const row in this.value) {
        if (row.relevance === 2) {
          return true
        }
      }
      return this.state.disabled
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone)
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        value = []
      }
      return value || []
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    rowLabel(row) {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(row, 'label')
    },
    isValidRelevance(row) {
      switch (row.relevance) {
        case 3: case 4:
          return ' display: none; '
        default:
          return ''
      }
    },
    isValidRelevanceReceived(row) {
      switch (row.relevance) {
        case 3: case 4:
          return ' display: none; '
        default:
          if (row.settings.show_send && !row.send) {
            return ' display: none; '
          }
          return ''
      }
    },
    isValidRelevanceComment(row) {
      switch (row.relevance) {
        case 3: case 4:
          return ' display: none; '
        default:
          if (row.settings.show_send && !row.send) {
            return ' display: none; '
          }
          if (row.settings.show_received && !row.received) {
            return ' display: none; '
          }
          return ''
      }
    },
    sendDateTime(row) {
      const builder = this.data.ChecklistBuilder
      return builder.getDateTime(row.send, { seconds: false })
    },
    receivedDateTime(row) {
      const builder = this.data.ChecklistBuilder
      return builder.getDateTime(row.received, { seconds: false })
    },
    showRowSend(row) {
      return !!row.settings.show_send
    },
    showRowReceived(row) {
      row = row || { settings: {} }
      return !!row.settings.show_received
    },
    showRowComment(row) {
      row = row || { settings: {} }
      return !!row.settings.show_comments
    },
    rowStatus(row) {
      switch (row.status) {
        case 2:
          return this.lang('ChecklistBuilder.misc.escalated')
        case 1:
          return this.lang('ChecklistBuilder.misc.reminded')
        default:
          return ''
      }
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content .document-selection-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
    text-align: left;
  }

  .checklist-viewer .element-content .document-selection-table
  thead {
    background: #fff;
  }

  .checklist-viewer .element-content .document-selection-table
  thead th {
    background: #fff;
    border-bottom: 2px solid #000;
    padding: 5px 0px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .checklist-viewer .element-content .document-selection-table
  tbody tr {
    background: #eee;
  }

  .checklist-viewer .element-content .document-selection-table
  tbody tr:nth-child(2n) {
    background: #fff;
  }

  .checklist-viewer .element-content .document-selection-table
  > tbody > tr > td:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .checklist-viewer .element-content .document-selection-table
  tbody td {
    padding: 1px;
  }

  .checklist-viewer .element-content .document-selection-table
  tbody td:first-child+td,
  .checklist-viewer .element-content .document-selection-table
  tbody td:last-child {
    text-align: center;
    vertical-align: middle;
  }

  .checklist-viewer .element-content .document-selection-table
  .relevance-type-3 {
    color: #00938f;
  }

  .checklist-viewer .element-content .document-selection-table
  .relevance-type-2 {
    color: #f00;
  }

  .checklist-viewer .element-content .document-selection-table
  .relevance-type-1 {
    color: #0a0;
  }

  .checklist-viewer .element-content .document-selection-table
  .relevance-type-0 {
    color: #666;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-action,
  .checklist-viewer .element-content .document-selection-table
  .document-relevance {
    height: 25px;
    width: 25px;
    font-size: 14px;
    display: inline-block;
    background: #f6f6f6 repeat-x scroll 50% 50%;
    border: 1px solid #cccccc;
    line-height: 1;
    margin: 0 1px 1px 0;
    padding: 0px;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-action,
  .checklist-viewer .element-content .document-selection-table
  .document-relevance {
    border-radius: 4px;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-action span,
  .checklist-viewer .element-content .document-selection-table
  .document-relevance span {
    color: #000;
    padding: 0px;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-select {
    width: 100%;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-send-date,
  .checklist-viewer .element-content .document-selection-table
  .document-received-date {
    display: inline-block;
    margin-left: 10px;
  }

  .checklist-viewer .element-content .document-selection-table
  .document-comment {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .checklist-viewer .element-content .document-selection-table
  button:not([disabled]):hover {
    -moz-box-shadow: inset 0 0 3px #9fc;
    -webkit-box-shadow: inset 0 0 3px #9fc;
    box-shadow: inset 0 0 3px #9fc;
  }

  .checklist-viewer .element-content.cell_type_document_management
  button[data-content-button-action="show_hidden_documents"] {
      margin-top: 10px;
      float: left;
  }

  .checklist-viewer .element-content .document-selection-table
  button i {
    font-size: 16px;
  }
</style>
